import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import TeamCard from './cards/team'
import Fade from 'react-reveal/Fade'

const Practice = ({ title, showFilters, practice, pageContext }) => {

  const contRef = React.useRef(null)

  const ourWorkRef = React.useRef(null)
  const ourPeopleRef = React.useRef(null)
  const careersRef = React.useRef(null)
  const ourStoryRef = React.useRef(null)

  // State
  const [toggle, setToggle] = React.useState(false)

  const scrollTop = (ref) => {
    setTimeout(() => {
      const $snapWrapper = document.querySelector('.snap-wrapper')
      const sliderHeight = window.innerHeight
      const headerHeight = window.innerWidth < 900 ? 62 : 93
      window.scrollTo({ top: sliderHeight })
      const scrollPos = $snapWrapper.scrollTop + ref.current.getBoundingClientRect().top - headerHeight
      $snapWrapper.scrollTo({ top: scrollPos, behavior: 'smooth' })
    }, 200)
  }

  React.useEffect(() => {
    const links = {
      'our-work': ourWorkRef, 'our-people': ourPeopleRef, 'our-story': ourStoryRef, 'careers': careersRef 
    }
    if (pageContext.type === 'practice' && links[pageContext.title]) {
      setToggle(true)
      scrollTop(links[pageContext.title])
    }
  }, [pageContext.id])


  const toggleActive = () => {
    setToggle(!toggle)
    scrollTop(contRef)
  }

  return (
    <div className={`accordion__item accordion__item--about ${toggle ? 'active' : ''}`} ref={contRef}>
      <button id='about' className='accordion__title' onClick={toggleActive}>
        <h4>{title}</h4>
        <span className='accordion__toggle' />
      </button>
      <div className='accordion__content'>
        <div className='practice'>
          <div className='practice__inner'>
            <div className='practice__sidebar' ref={ourWorkRef}>
              <Fade distance='40px' bottom>
                <h3>{ 'Our Work' }</h3>
              </Fade>
            </div>
            <div className='practice__main'>
              <Fade distance='40px' bottom>
                <>
                  <div dangerouslySetInnerHTML={{ __html: practice.ourWork.description }} />
                  {practice.ourWork.list?.length > 0 &&
                    <ul>
                      {practice.ourWork.list.map((el, i) => (
                        <li key={i}>{el.text}</li>
                      ))}
                    </ul>
                  }
                  {practice.ourWork.quote.phrase &&
                    <div className='practice__quote'>
                      <h4 dangerouslySetInnerHTML={{ __html: practice.ourWork.quote.phrase }} />
                      <p>{practice.ourWork.quote.person} — <em>{practice.ourWork.quote.title}</em><br /><span>{practice.ourWork.quote.company}</span></p>
                    </div>
                  }
                </>
              </Fade>
            </div>
            <div className='practice__sidebar' ref={ourPeopleRef}>
              <Fade distance='40px' bottom>
                <h3>{ 'Our People' }</h3>
              </Fade>
            </div>
            <div className='practice__main'>
              <div className='practice__team'>
                {practice.team?.map((el, i) => (
                  <TeamCard key={i} {...el} />
                ))}
              </div>
            </div>
            <div className='practice__sidebar' ref={careersRef}>
              <Fade distance='40px' bottom>
                <h3>{ 'Careers / Job Opportunities' }</h3>
              </Fade>
            </div>
            <div className='practice__main'>
              <Fade distance='40px' bottom>
                <div className='practice__careers' dangerouslySetInnerHTML={{ __html: practice.careers }} />
              </Fade>
            </div>
            <div className='practice__image'>
              <Fade distance='40px' bottom>
                <GatsbyImage image={getImage(practice.image.localFile)} alt={`Entertech - Practice`} />
              </Fade>
            </div>
            <div className='practice__sidebar' ref={ourStoryRef}>
              <Fade distance='40px' bottom>
                <h3>{ 'Our Story' }</h3>
              </Fade>
            </div>
            <div className='practice__main'>
              <Fade distance='40px' bottom>
                <>
                  <div dangerouslySetInnerHTML={{ __html: practice.history.intro }} />
                  <div dangerouslySetInnerHTML={{ __html: practice.history.bodyContent }} />
                </>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Practice 

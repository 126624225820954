import React from 'react'

import usePostQuery from "../hooks/use-post-query"
//import useUrlUpdate from "../hooks/use-url-update"

import ArticleCard from './cards/article'
import Post from './cards/post'

const News = ({ title, pageContext }) => {
  // Data
  const posts = usePostQuery()

  // State
  const [activePost, setActivePost] = React.useState(posts?.length - 1 || 0)
  const [showPost, setShowPost] = React.useState(false)
  const [list, setList] = React.useState(posts)
  const [limit, setLimit] = React.useState(6)
  const [toggle, setToggle] = React.useState(false)
  const activeRef = React.useRef(null)
  const contRef = React.useRef(null)

  const scrollTop = (ref) => {
    setTimeout(() => {
      const $snapWrapper = document.querySelector('.snap-wrapper')
      const headerHeight = window.innerWidth < 900 ? 62 : 93 
      const sliderHeight = window.innerHeight
      if (!ref.current) return;
      window.scrollTo({ top: sliderHeight })
      const scrollPos = $snapWrapper.scrollTop + ref.current?.getBoundingClientRect().top - headerHeight
      $snapWrapper.scrollTo({ top: scrollPos, behavior: 'smooth' })
    }, 100)
  }

  React.useEffect(() => {
    if (pageContext.type === 'post') {
      const index = posts.findIndex(el => el.id === pageContext.id)
      if (index > -1) {
        setActivePost(index)
        setToggle(true)
        setShowPost(true)
        scrollTop(activeRef)
      }
    }
  }, [])

  const onClick = React.useCallback((i, nav) => {
    if (showPost && i === activePost) {
      setShowPost(false)
      setActivePost(list?.length - 1 || 0)
      window.history.pushState({}, '', `/`)
      window.title = `Studio Entertech`
      scrollTop(contRef)
      return;
    }
    setShowPost(true)
    if (nav === 'prev') {
      i = i === 0 ? list.length - 1 : i - 1
    }
    if (nav === 'next') {
      i = i === list.length - 1 ? 0 : i + 1
    }
    setActivePost(i)
    window.history.pushState({}, '', list[i].uri)
    document.title = list[i].seo.title
    scrollTop(activeRef)
  }, [list, activePost]);

  const onClose = React.useCallback((i) => {
    setShowPost(false)
    setActivePost(list?.length - 1 || 0)
    window.history.pushState({}, '', `/`)
    window.title = `Studio Entertech`
    scrollTop(contRef)
  }, []);

  const toggleActive = () => {
    setToggle(!toggle)
    scrollTop(contRef)
  }

  const renderItems = () => {
    return (
      <div className='news__items'>
        {list?.slice(0, limit).map((el, i, arr) => {
          let activeDisplay = null
          if (((i + 1) % 3 === 0 || i + 1 === arr.length) && activePost <= i && activePost >= i - 2) {
            activeDisplay = (
              <div ref={activeRef} style={{width: '100%'}}>
                {showPost &&
                  <Post {...list[activePost]} i={activePost} onClick={onClick} onClose={onClose} />
                }
              </div>
            )
          }
          return (
            <React.Fragment key={el.id}>
              <ArticleCard {...el} i={i} onClick={onClick} />
              { activeDisplay }
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  return (
    <div className={`accordion__item accordion__item--news ${toggle ? 'active' : ''}`} ref={contRef}>
      <button id='news' className='accordion__title' onClick={toggleActive}>
        <h4>{title}</h4>
        <span className='accordion__toggle' />
      </button>
      <div className='accordion__content'>
        {renderItems()}
        {limit <= list.length &&
          <p className='news__more'>
            <button onClick={() => setLimit(limit + 6)}>Load More</button>
          </p>
        }
        {list?.length === 0 && <p className='news__no-results'>No news at this time.</p>}
      </div>
    </div>
  )
}

export default News

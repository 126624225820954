import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import layoutQuery from "../hooks/use-layout-query"
import RenderBlock from '../utils/render-block'

import '../assets/scss/main.scss'

import ProjectBanner from '../components/cards/project-banner'
import Header from '../components/header'
import Footer from '../components/footer'
import Fade from 'react-reveal/Fade'

const settings = {
  speed: 600,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows: false,
  cssEase: 'ease',
  swipe: true,
  focusOnSelect: false,
  adaptiveHeight: true,
  pauseOnHover: false,
}

const urls = [
  { url: '/', id: 'index' },
  { url: '/news/', id: 'news' },
  { url: '/projects/', id: 'projects' },
  { url: '/about/', id: 'about' }, // practice
]

const Layout = ({ children, location, pageContext, data }) => {
  const layoutData = layoutQuery()
  const content = layoutData.wpPage.acf.contentBlocks?.map((el, i) => RenderBlock(el.fieldGroupName?.replace('page_Acf_ContentBlocks_', ''), el, i, pageContext))

  const navClick = (_id) => {
    const $item = document.querySelector(`#${_id}`)
    if (!$item) return
    const $snapWrapper = document.querySelector('.snap-wrapper')
    const sliderHeight = window.innerHeight * 2
    if (!document.querySelector(`.accordion__item--${_id}`).classList.contains('active')) {
      $item.click()
    } else {
      const headerHeight = window.innerWidth < 900 ? 62 : 93
      const scrollPos = $snapWrapper.scrollTop + $item.getBoundingClientRect().top - headerHeight
      window.scrollTo({ top: sliderHeight + 1 })
      $snapWrapper.scrollTo({ top: scrollPos, behaviour: 'smooth' })
    }
  }

  React.useEffect(() => {
    let timeout = null
    const onScroll = () => {
      if (window.scrollY !== window.innerHeight && document.querySelector('.snap-wrapper').scrollTop !== 0) {
        timeout = setTimeout(() => {
          if (window.scrollY !== window.innerHeight && document.querySelector('.snap-wrapper').scrollTop !== 0) {
            window.scroll({ top: window.innerHeight, left: 0, behavior: 'smooth' })
          }
        }, 1000)
      } else {
        clearTimeout(timeout)
      }
    }

    const item = urls.find(url => url.url === data?.wpPage?.uri)
    if (item) {
      // Scroll to item
      navClick(item.id)
    }

    document.querySelector('.snap-wrapper')?.addEventListener('scroll', onScroll)

    return () => {
      document.querySelector('.snap-wrapper')?.removeEventListener('scroll', onScroll)
    }
  }, [])



  if (data?.wpPage && !urls.find(url => url.url === data?.wpPage?.uri)) {
    return (
      <>
       { children }
      </>
    )
  }

  return (
    <>
      <div className='project-banner__container'>
        <Slider {...settings}>
          { layoutData.wpPage.acf.homeBanner?.map((el, i) => (
            <ProjectBanner key={i} index={i + 1} length={layoutData.wpPage.acf.homeBanner.length} {...el} />
          ))}
        </Slider>
      </div>
      <div className='snap-wrapper'>
        <Header location={location} data={layoutData} pageContext={pageContext} />
        <section className='about snap-wrapper__about'>
          <Fade distance='40px' bottom>
            <div className='about__inner'>
              <div className='about__content' dangerouslySetInnerHTML={{ __html: layoutData.wpPage.acf.aboutUs }} />
              <button onClick={() => navClick('projects')}>
                Discover our work
              </button>
            </div>
          </Fade>
        </section>
        <main className='accordion'>
          { content }
        </main>
        <Footer data={layoutData} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
